var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-10"},[_c('v-row',[_c('v-col',[_c('v-sheet',[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"border":"1px solid #e0e0e0","border-bottom":"none","padding-right":"15px"}},[_c('div',{staticStyle:{"max-width":"60px","width":"100%","border-right":"1px solid #e0e0e0"}},[_c('h1',{},[_vm._v(" "+_vm._s(_vm.userData["departmentName-" + _vm.userData.school.currentYear] + _vm.userData["classGroupName-" + _vm.userData.school.currentYear])+" ")])]),_vm._l(([
              'Даваа',
              'Мягмар',
              'Лхавга',
              'Пүрэв',
              'Баасан',
              'Бямба',
              'Ням',
            ]),function(days){return _c('v-col',{key:days,staticClass:"text-center pt-3",staticStyle:{"border-right":"1px solid #e0e0e0"}},[_vm._v(" "+_vm._s(days)+" ")])})],2),_c('v-calendar',{ref:"calendar",attrs:{"hide-header":"","color":"primary","type":"week","first-interval":6,"interval-count":15,"events":_vm.events}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }