<template>
  <v-container class="pa-10">
    <v-row>
      <v-col>
        <v-sheet>
          <div
            class="d-flex flex-row"
            style="
              border: 1px solid #e0e0e0;
              border-bottom: none;
              padding-right: 15px;
            "
          >
            <div
              style="
                max-width: 60px;
                width: 100%;
                border-right: 1px solid #e0e0e0;
              "
            >
              <h1 class="">
                {{
                  userData["departmentName-" + userData.school.currentYear] +
                  userData["classGroupName-" + userData.school.currentYear]
                }}
              </h1>
            </div>

            <v-col
              v-for="days in [
                'Даваа',
                'Мягмар',
                'Лхавга',
                'Пүрэв',
                'Баасан',
                'Бямба',
                'Ням',
              ]"
              class="text-center pt-3"
              :key="days"
              style="border-right: 1px solid #e0e0e0"
            >
              {{ days }}
            </v-col>
          </div>
          <v-calendar
            ref="calendar"
            hide-header
            color="primary"
            type="week"
            :first-interval="6"
            :interval-count="15"
            :events="events"
          >
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    events: [],
  }),
  computed: {
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },

  methods: {},
  created() {
    this.userData["classGroup-" + this.userData.school.currentYear]
      .collection("huvaariData-" + this.userData.school.currentYear)
      .onSnapshot((docs) => {
        this.events = [];
        docs.forEach((doc) => {
          let event = doc.data();
          event.ref = doc.ref;
          event.id = doc.id;
          this.events.push(event);
        });
      });
  },
};
</script>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
